import React, { FunctionComponent, SyntheticEvent } from 'react';
import { IProps as IBoxProps } from './Box';
import Text from './Text';
import { FelaCSS } from './flowtypes';

// Browser button is rendered as div with button role because button element is
// hard to style consistently in Firefox and maybe elsewhere. Div is just fine.
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_button_role

export interface IProps extends IBoxProps {
  disabled?: boolean;
  style?: FelaCSS;
}

const BrowserButton = ({
  disabled,
  onPress,
  ...restProps
}: {
  disabled?: boolean;
  onPress?: (e: SyntheticEvent<any>) => void;
}) => {
  const props = {
    onKeyPress: (e: any) => {
      if (disabled) return;
      // Buttons are expected to be triggered using the Space or Enter key.
      const isTriggered = e.key === ' ' || e.key === 'Enter';
      if (!isTriggered) return;
      // Prevent scroll on spacebar press.
      e.preventDefault();
      onPress(e);
    },
    role: 'button',
    tabIndex: disabled ? -1 : 0,
    onClick: onPress,
    ...restProps,
  };

  return <div {...props} />;
};

const Button: FunctionComponent<IProps> = ({
  as = BrowserButton,
  style,
  disabled,
  ...restProps
}) => {
  const buttonStyles: FelaCSS = {
    cursor: disabled ? 'default' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    ...style,
  };
  return <Text as={as} style={buttonStyles} {...restProps} />;
};

export default Button;
