import React, { PropsWithChildren, ReactNode, SyntheticEvent } from 'react';
import { Box, FelaCSS, TButtonSize, TColor, TSizeValues } from '@bridebook/ui';
import FelaButton from '../../../../components/fela/Button';
import IconChevronFat from '../../../../components/svg/icon-chevron-fat';
import mergeStyles from '../../../../fela-utils/merge-styles';
import { TIconPosition } from '../types';
import componentStyles from './button-ghost.style';

interface IProps extends PropsWithChildren {
  onClick?: (e: SyntheticEvent<any>) => any;
  onMouseEnter?: Function;
  ref?: Function;
  disabled?: boolean;
  noIcon?: boolean;
  noUnderline?: boolean;
  name?: string;
  id?: string;
  theme?: string;
  icon?: ReactNode;
  backIcon?: ReactNode;
  floatingIcon?: boolean;
  iconPosition?: TIconPosition;
  w?: TSizeValues;
  h?: TSizeValues;
  size?: TButtonSize;
  style?: FelaCSS;
  iconFillColor?: TColor;
}

const ButtonGhost = ({
  onClick,
  onMouseEnter,
  ref,
  disabled,
  noIcon,
  noUnderline,
  name,
  id,
  theme,
  icon,
  backIcon,
  floatingIcon,
  iconPosition,
  w,
  h,
  size,
  style = {},
  iconFillColor,
  children,
  ...restProps
}: IProps) => {
  const showBackIcon = !noIcon;

  const styles = componentStyles({
    disabled,
    theme,
    noUnderline,
    w,
    h,
    size,
    children,
    backIcon,
    icon,
    floatingIcon,
    iconPosition,
    iconFillColor,
  });

  return (
    <FelaButton
      style={mergeStyles([styles.wrapper, style])}
      data-name={name || 'button-ghost'}
      disabled={disabled}
      onPress={onClick}
      onMouseEnter={onMouseEnter}
      ref={ref}
      id={id}
      {...restProps}>
      {icon && <Box style={styles.iconWrap}>{icon}</Box>}
      {children}
      {showBackIcon && (
        <Box style={styles.backIconWrap}>
          {backIcon ? (
            backIcon
          ) : (
            <>
              {!icon && (
                <Box style={styles.chevronWrap}>
                  <IconChevronFat width={11} />
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </FelaButton>
  );
};

export default ButtonGhost;
