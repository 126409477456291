import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconChevronFat = ({ id, color, width = 11, style, ...restProps }: IProps) => {
  const viewBoxWidth = 11;
  const viewBoxHeight = 7;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M10.8,0.3C10.6,0.1,10.4,0,10.1,0S9.6,0.1,9.4,0.3L5.6,4.1L1.7,0.3C1.5,0.1,1.3,0,1,0C0.7,0,0.5,0.1,0.3,0.3
  C0.1,0.5,0,0.8,0,1s0.1,0.5,0.3,0.7C3.4,4.8,5,6.4,5,6.4l0.1,0.1c0,0,0.1,0,0.2,0.1h0.1l0.1,0c0,0,0,0,0.1,0h0.1
  C5.9,6.5,6,6.5,6,6.5c0,0,0,0,0,0s0,0,0.1,0c0,0,0.1,0,0.1-0.1l4.7-4.6C11,1.5,11.1,1.3,11.1,1S11,0.5,10.8,0.3z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconChevronFat;
