import React, { PropsWithChildren } from 'react';
import { IStylingProps } from '../../../themes/types';
import Box from '../../fela/Box';
import { FelaCSS } from '../../fela/flowtypes';
import componentStyles from './multiline-ellipsis.style';

interface IProps extends IStylingProps, PropsWithChildren {
  fontSize?: number;
  lineHeight?: number;
  lines?: number;
  style?: FelaCSS;
}

const MultilineEllipsis = ({
  children,
  fontSize = 16,
  lineHeight = 20,
  lines: maxLines = 3,
  style = {},
  ...restProps
}: IProps) => {
  const styles = componentStyles({ fontSize, lineHeight, maxLines });

  return (
    <Box style={{ ...styles.wrapper, ...style }} {...restProps}>
      {children}
    </Box>
  );
};

export default MultilineEllipsis;
