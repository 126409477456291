import { ReactNode } from 'react';
import {
  FelaCSS,
  FelaCSSWithCustomSelectors,
  TButtonSize,
  TButtonTheme,
  TSizeValues,
  colors,
} from '@bridebook/ui';
import getSizeValue from '../../../../fela-utils/get-size-value';
import { TIconPosition } from '../types';
import { getThemeColors, getThemeSize } from './themes';

interface IStyleProps {
  theme?: TButtonTheme;
  size?: TButtonSize;
  w?: TSizeValues;
  disabled?: boolean;
  equalPadding?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
  iconPosition?: TIconPosition;
  floatingIcon?: boolean;
}

interface IStyles {
  wrapper: FelaCSS;
  iconWrap: FelaCSSWithCustomSelectors;
  contentWrap: FelaCSS;
}

const styles = ({
  icon,
  children,
  theme,
  size,
  disabled,
  w,
  equalPadding,
  iconPosition,
  floatingIcon,
}: IStyleProps): IStyles => {
  const themeColors = getThemeColors(disabled ? 'disabled' : theme);
  const sizeTheme = getThemeSize(size);
  const isCenteredText = icon && equalPadding;

  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: icon && !floatingIcon ? undefined : sizeTheme.padding,
      paddingRight: children ? (isCenteredText ? sizeTheme.size : sizeTheme.padding) : undefined,
      height: sizeTheme.size,
      borderRadius: '6px',
      border: themeColors.border ? `1px solid ${themeColors.border}` : undefined,
      color: themeColors.color,
      backgroundColor: themeColors.bg,
      fontDefaultSemiBold: sizeTheme.fontSize,
      ...getSizeValue('width', w),

      ':hover': {
        paddingBottom: '3px',
        backgroundColor: themeColors.bgHover,
        boxShadow: `inset 0px -3px 0px 0px ${themeColors.shadow}`,
      },
    },

    iconWrap: {
      ...(floatingIcon
        ? {
            position: 'absolute',
            [iconPosition]: '15px',
          }
        : {
            alignItems: 'center',
            justifyContent: 'center',
            width: sizeTheme.size,
            height: '100%',
          }),

      '& path': {
        fill: disabled ? colors.space15 : undefined,
      },
    },

    contentWrap: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
};

export default styles;
