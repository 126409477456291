import type { Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { mapSlugToFriendlyUrlSlug } from '../mapSlugToFriendlyUrlSlug';
import slugify from '../slugify';
import { toUrlQuery } from '../toUrlQuery';

type Filters = Record<string, Parameters<typeof encodeURIComponent>[0]>;
const isSingleBooleanFilter = (filters: Filters) =>
  !filters?.ourVenue &&
  !filters?.capacityDining &&
  !filters?.radiusIncrease &&
  Object.values(filters).filter(Boolean).length === 1;
/**
 * For single filter returns url part with facet value, e.g. /hotel,
 * for multiple filters use query params, e.g. /?hotel=true&historic=true
 */
const getFilterUrlPart = (filters: Filters, prefix: Parameters<typeof toUrlQuery>[1]) =>
  isSingleBooleanFilter(filters) ? `/${Object.keys(filters)[0]}` : toUrlQuery(filters, prefix);

export type TGetSearchUrlWithFiltersParams = {
  slug?: Slug | UrlFriendlySlug;
  area?: string;
  placeId?: string;
  areaContext?: string[];
  filters: Filters;
  as?: boolean;
};

const getSearchUrlWithFilters = ({
  slug,
  area,
  placeId,
  areaContext,
  filters,
  as = false,
}: TGetSearchUrlWithFiltersParams): string => {
  const category = mapSlugToFriendlyUrlSlug(slug).newSlug;
  const areaWithContext =
    area && areaContext && areaContext.length > 0
      ? `${area}--${areaContext.join('--')}`
      : area || 'uk';
  const slugArea: string = slugify(areaWithContext, true); // Having this location lowercase is important for SEO LIVE-16611
  const prefix = isSingleBooleanFilter(filters) ? '' : '?';
  const filterPostfix = isSingleBooleanFilter(filters) ? '?' : '&';

  let url: string = as
    ? `/search/wedding-${category}/${slugArea}${getFilterUrlPart(filters, prefix)}`
    : `/search${getFilterUrlPart(
        filters,
        prefix,
      )}${filterPostfix}area=${slugArea}&slug=${category}`;

  if (placeId) url += (url.indexOf('?') === -1 ? '?' : '&') + `placeId=${placeId}`;
  return url;
};

export default getSearchUrlWithFilters;
