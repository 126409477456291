import { FelaCSS } from '../../fela/flowtypes';

interface IStyleProps {
  fontSize: number;
  lineHeight: number;
  maxLines: number;
}

interface IStyles {
  wrapper: FelaCSS;
}

const styles = ({ fontSize, lineHeight, maxLines }: IStyleProps): IStyles => ({
  wrapper: {
    display: 'block' /* Fallback for non-webkit */,
    // @ts-ignore
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: maxLines,
    margin: '0 auto',
    overflow: 'hidden',
    fontDefault: fontSize,
    lineHeight: `${lineHeight}px`,
  },
});

export default styles;
