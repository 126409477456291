import React, { MouseEventHandler, ReactNode } from 'react';
import { IStylingProps, TColor } from '../../../themes/types';
import { TButtonSize, TButtonTheme, TButtonWidth } from '../../../types';
import { FelaCSS } from '../../fela/flowtypes';
import ButtonGhost from './button-ghost/button-ghost';
import ButtonShell from './button-shell/button-shell';
import { TIconPosition } from './types';

export interface IProps extends IStylingProps {
  name?: string;
  id?: string;
  backIcon?: ReactNode;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  type?: string;
  ref?: Function;
  noIcon?: boolean;
  style?: FelaCSS;
  theme?: TButtonTheme;
  size?: TButtonSize;
  width?: TButtonWidth;
  /**  works only with width set to fixed */
  fixedWidth?: number;
  shadow?: boolean;
  text?: string | ReactNode;
  icon?: ReactNode;
  iconPosition?: TIconPosition;
  floatingIcon?: boolean;
  disabled?: boolean;
  // Show loading spinner
  loading?: boolean;
  /**  works only for ghost theme */
  color?: TColor;
  noUnderline?: boolean;
  customHeight?: number;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  customWidth?: number;
  iconFillColor?: TColor;
  dataTest?: string;
}

/**
 * @deprecated This component is deprecated. Please use @bridebook/ui/src/components/bbcommon/button/button instead
 */
export const Button = ({
  theme,
  size,
  width,
  fixedWidth,
  text,
  icon,
  iconPosition = 'left',
  color,
  disabled,
  customWidth,
  customHeight,
  noUnderline,
  name,
  id,
  backIcon,
  onClick,
  onMouseEnter,
  ref,
  noIcon,
  loading,
  floatingIcon,
  style,
  iconFillColor,
  ...restProps
}: IProps) => {
  const isGhost = theme === 'ghost';
  const isFullWidth = width === 'block';
  const isFixedWidth = width === 'fixed';

  return isGhost ? (
    <ButtonGhost
      style={style}
      theme={color}
      onClick={onClick}
      ref={ref}
      w={isFullWidth ? '100%' : customWidth}
      h={customHeight}
      noUnderline={noUnderline}
      onMouseEnter={onMouseEnter}
      disabled={disabled}
      noIcon={noIcon}
      name={name}
      id={id}
      icon={icon}
      backIcon={backIcon}
      size={size}
      floatingIcon={floatingIcon}
      iconPosition={iconPosition}
      iconFillColor={iconFillColor}
      {...restProps}>
      {text}
    </ButtonGhost>
  ) : (
    <ButtonShell
      style={style}
      theme={theme}
      size={size}
      w={isFullWidth ? '100%' : fixedWidth}
      icon={icon}
      floatingIcon={floatingIcon}
      iconPosition={iconPosition}
      disabled={disabled}
      loading={loading}
      name={name}
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      ref={ref}
      equalPadding={isFullWidth || isFixedWidth}
      {...restProps}>
      {text}
    </ButtonShell>
  );
};

export default Button;
