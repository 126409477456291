// Hook
import { useEffect, useRef } from 'react';

/**
 * Hook used to keep previous value between re-renders.
 * Used mainly to compare props instead of componentWillReceiveProps
 * @param value
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
