import msg, { MsgKey } from 'lib/supplier/msg';

export type IPhotographyStyleOption = {
  key: string;
  primaryText: string;
  value: string;
};

export const getPhotographyStyleOptions = (): IPhotographyStyleOption[] =>
  [
    'traditionalClassic',
    'reportageDocumentary',
    'modern',
    'natural',
    'artistic',
    'editorial',
    'dramatic',
    'alternative',
  ].map((key) => ({
    key,
    primaryText: msg[key as MsgKey](),
    value: key,
  }));
