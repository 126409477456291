import { ReactNode } from 'react';
import {
  FelaCSS,
  FelaCSSWithCustomSelectors,
  TButtonSize,
  TColor,
  TSizeValues,
  colors,
} from '@bridebook/ui';
import getSizeValue from '../../../../fela-utils/get-size-value';
import { TIconPosition } from '../types';
import { getThemeColor, getThemeSize } from './themes';

interface IStyleProps {
  theme?: string;
  disabled?: boolean;
  noUnderline?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  backIcon?: ReactNode;
  floatingIcon?: boolean;
  iconPosition?: TIconPosition;
  w?: TSizeValues;
  h?: TSizeValues;
  size?: TButtonSize;
  iconFillColor?: TColor;
}

interface IStyles {
  wrapper: FelaCSSWithCustomSelectors;
  iconWrap: FelaCSS;
  backIconWrap: FelaCSS;
  chevronWrap: FelaCSS;
}

const styles = ({
  theme,
  disabled,
  noUnderline,
  children,
  w,
  h,
  size,
  icon,
  floatingIcon,
  iconPosition,
  backIcon,
  iconFillColor,
}: IStyleProps): IStyles => {
  const themeColor = getThemeColor(disabled ? 'disabled' : theme);

  const width: FelaCSS = w
    ? getSizeValue('width', w)
    : children
    ? { width: 'auto' }
    : { width: getThemeSize(size) };

  const height: FelaCSS = h
    ? getSizeValue('height', h)
    : children
    ? { height: 'auto' }
    : { height: getThemeSize(size) };

  return {
    wrapper: {
      ...width,
      ...height,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      fontDefaultSemiBold: 14,
      color: themeColor,

      '& path': {
        fill: colors[iconFillColor] || themeColor,
      },

      ':hover': {
        textDecoration: noUnderline || !children ? undefined : 'underline',
      },
    },

    iconWrap: {
      marginRight: children ? (icon ? '10px' : '5px') : undefined,
    },

    backIconWrap: {
      marginLeft: children ? (backIcon ? '10px' : '5px') : undefined,
      ...(floatingIcon && { position: 'absolute', [iconPosition]: '15px' }),
    },

    chevronWrap: {
      transform: 'rotate(-90deg)',
    },
  };
};

export default styles;
