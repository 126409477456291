import React, { FunctionComponent, memo } from 'react';
import { colors } from '../../fela-themes';
import { IColorTypes, IFontTypes } from '../../themes/types';
import Box, { IProps as IBoxProps } from './Box';
import { FelaCSS } from './flowtypes';

interface IProps extends IBoxProps, IFontTypes {
  lh?: FelaCSS['lineHeight'];
  color?: keyof IColorTypes;

  textAlign?: FelaCSS['textAlign'];
}

const Text: FunctionComponent<IProps> = ({
  style,
  textAlign,
  lh,
  fontDefault,
  fontDefaultBold,
  fontDefaultItalic,
  fontDefaultLight,
  fontDefaultSemiBold,
  color,
  ...restProps
}) => {
  const textStyles: FelaCSS = {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    msHyphens: 'auto',
    textAlign,
    ...(fontDefault && { fontDefault }),
    ...(fontDefaultBold && { fontDefaultBold }),
    ...(fontDefaultItalic && { fontDefaultItalic }),
    ...(fontDefaultLight && { fontDefaultLight }),
    ...(fontDefaultSemiBold && { fontDefaultSemiBold }),
    ...(lh && { lineHeight: typeof lh === 'number' ? `${lh}px` : lh }),
    ...(color && { color: colors[color] }),
    ...style,
  };

  return <Box {...restProps} style={textStyles} />;
};

export default memo(Text);
