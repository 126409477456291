import { TButtonSize, TButtonTheme } from '@bridebook/ui';
import { colors } from '../../../../themes/variables';

export const getThemeColors = (theme: TButtonTheme) => {
  switch (theme) {
    case 'primary':
      return {
        bg: colors.mint,
        bgHover: colors.mint80,
        shadow: colors.mint140,
        color: colors.white,
      };
    case 'secondary':
      return {
        bg: colors.indigoCrush,
        bgHover: colors.indigoCrush80,
        shadow: colors.indigoCrush120,
        color: colors.white,
      };
    case 'yellow':
      return {
        bg: colors.peachRose120,
        bgHover: colors.peachRose,
        shadow: colors.peachRose120,
        color: colors.white,
      };
    case 'alert':
      return {
        bg: colors.blushTangerine120,
        bgHover: colors.blushTangerine,
        shadow: colors.blushTangerine120,
        color: colors.white,
      };
    case 'disabled':
      return {
        bg: colors.space08,
        bgHover: colors.space08,
        shadow: colors.space08,
        color: colors.white,
      };
    case 'google':
      return {
        bg: colors.white,
        bgHover: colors.space04,
        shadow: colors.space30,
        color: colors.space,
        border: colors.space40,
      };
    case 'facebook':
      return {
        bg: colors.facebookBlue,
        bgHover: colors.facebookBlue80,
        shadow: colors.facebookBlue140,
        color: colors.white,
      };
    case 'apple':
      return {
        bg: colors.black,
        bgHover: colors.mineShaft,
        shadow: colors.mineShaft,
        color: colors.white,
      };
    case 'mint':
      return {
        bg: colors.white,
        color: colors.teal120,
        border: colors.teal120,
      };
    default:
      return {
        bg: colors.space15,
        bgHover: colors.space08,
        shadow: colors.space60,
        color: colors.space,
      };
  }
};

export const getThemeSize = (size?: TButtonSize) => {
  switch (size) {
    case 'big':
      return {
        size: '60px',
        padding: '30px',
        fontSize: 18,
      };
    case 'tiny':
      return {
        size: '34px',
        padding: '10px',
        fontSize: 14,
      };
    default:
      return {
        size: '44px',
        padding: '20px',
        fontSize: 14,
      };
  }
};
