export enum IconClass {
  Approve = 'bb-icons-approve',
  AwardBadge = 'bb-icons-award-badge',
  Beauty = 'bb-icons-beauty',
  Bedroom = 'bb-icons-bedroom',
  BookmarkFlag = 'bb-icons-bookmark-flag',
  Budget = 'bb-icons-budget',
  BudgetMatch = 'bb-icons-budget-match',
  BudgetMatchTick = 'bb-icons-budget-match-with-tick',
  Cake = 'bb-icons-cake',
  Calendar = 'bb-icons-calendar',
  Catering = 'bb-icons-catering',
  Checked = 'bb-icons-checked',
  Decoration = 'bb-icons-decoration',
  Dress = 'bb-icons-dress',
  Email = 'bb-icons-email',
  Entertainment = 'bb-icons-entertainment',
  Favourites = 'bb-icons-favourites',
  FeaturedSupplier = 'bb-icons-featured-supplier',
  Florist = 'bb-icons-florist',
  Guest = 'bb-icons-guest',
  Home = 'bb-icons-home',
  Hump = 'bb-icons-hump',
  LocationFilled = 'bb-icons-location-filled',
  Marquee = 'bb-icons-marquee',
  Menswear = 'bb-icons-menswear',
  Music = 'bb-icons-music',
  Note = 'bb-icons-note',
  Pencil = 'bb-icons-pencil',
  Photographer = 'bb-icons-photographer',
  Planner = 'bb-icons-planner',
  Review = 'bb-icons-review',
  ReviewFilled = 'bb-icons-review-filled',
  Ring = 'bb-icons-ring',
  Scrapbook = 'bb-icons-scrapbook',
  Search = 'bb-icons-search',
  Settings = 'bb-icons-settings',
  Shortlist = 'bb-icons-shortlist',
  ShortlistFilled = 'bb-icons-shortlist-filled',
  Stationery = 'bb-icons-stationery',
  TickBold = 'bb-icons-tickBold',
  Transport = 'bb-icons-transport',
  Trash = 'bb-icons-trash',
  Venue = 'bb-icons-venue',
  Video = 'bb-icons-video',
  Winter = 'bb-icons-winter',
  Summer = 'bb-icons-summer',
  Spring = 'bb-icons-spring',
  Autumn = 'bb-icons-autumn',
  Tip = 'bb-icons-tip',
}
