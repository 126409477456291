import React, { MouseEventHandler, ReactNode } from 'react';
import { Box, TColor } from '@bridebook/ui';
import componentStyles from './icon-round-button.style';

export interface IProps {
  icon: JSX.Element;
  size: number;
  border?: boolean;
  borderColor?: string;
  boxShadow?: boolean;
  disabled?: boolean;
  backgroundColor?: TColor;
  customBackgroundColor?: string;
  onClick?: MouseEventHandler<ReactNode>;
}

const IconRoundButton = ({
  icon,
  size,
  disabled,
  onClick,
  border,
  borderColor,
  boxShadow,
  backgroundColor = 'white',
  customBackgroundColor,
  ...restProps
}: IProps) => {
  const styles = componentStyles(
    size,
    border,
    borderColor,
    boxShadow,
    backgroundColor,
    customBackgroundColor,
    !!onClick,
    disabled,
  );

  return (
    <Box as="button" onClick={disabled ? undefined : onClick} style={styles.button} {...restProps}>
      {icon}
    </Box>
  );
};

IconRoundButton.displayName = 'IconRoundButton';

export default IconRoundButton;
