import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { ImageSize } from 'lib/api/instagram/types';

export const ApiEndpoint = {
  abTests: '/api/ab-tests',
  articles: {
    list: '/api/articles/list',
    listByIDs: '/api/articles/list-by-ids',
    search: '/api/articles/search',
  },
  compareSuppliers: '/api/supplier/compare',
  collaboration: {
    getFiles: '/api/files/get',
  },
  i18n: {
    getViewerCountry: '/api/i18n/get-viewer-country',
  },
  inbox: {
    archiveConversation: '/api/inbox/archive-conversation',
    getConversations: (weddingId: string) => `/api/inbox/conversations/${weddingId}`,
    initTalkConversation: '/api/inbox/init-talk-conversation',
    sendMessage: '/api/inbox/send-message',
    update: (id: string) => `/api/inbox/users/${id}/update`,
  },
  instagram: {
    getMedia: (supplierId: string, limit: number, thumbSize: ImageSize) =>
      `/api/instagram/${supplierId}?limit=${limit}&thumbSize=${thumbSize}`,
  },
  userSession: '/api/auth/user-session',
  recommendations: {
    getRex: '/api/rex/get-rex',
  },
  search: {
    main: '/api/search',
    count: '/api/search/count',
    supplierTypes: '/api/search/supplier-types',
    suggestions: '/api/search/suggestions',
    validPresets: (countryCode: CountryCodes) =>
      `/api/search/valid-presets?countryCode=${countryCode}`,
    locations: '/api/search/locations',
    ads: '/api/search/ads',
  },
  supplier: {
    feedback: {
      add: '/api/supplier/feedback/add',
    },
    fetch: (publicId: string) => `/api/supplier/${publicId}`,
    data: (publicId: string, locale?: string) =>
      `/api/supplier/${publicId}/data${locale ? '?locale=' + locale : ''}`,
    suppliers: `/api/supplier/fetch-suppliers`,
  },
  translations: {
    supplier: `/api/translation/supplier`,
  },
  authenticate: '/api/auth/authenticate',
  logout: '/api/auth/logout',
  wedding: {
    feedback: {
      sendFeedbackRequestNotification: '/api/wedding/feedback/send-feedback-request-notification',
      sendFeedbackResponseNotification: '/api/wedding/feedback/send-feedback-response-notification',
    },
  },
};
