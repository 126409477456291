import React, { Component, PropsWithChildren, RefCallback, memo } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import type { AnyAction, Store } from 'redux';
import { Box, TColor } from '@bridebook/ui';
import {
  addPositionInlineStyles,
  scrollPositionKeep,
  windowScrollPositionSet,
} from 'lib/bbcommon/utils/scroll-position-keep';
import componentStyles, { ITransitionState } from './modal.style';

export interface IModalProps {
  id: string;
  name?: string;
  show: boolean;
  onClose?: () => void;
  alwaysMounted?: boolean;
  keepScrollPosition?: boolean;
  maxWidth?: string | number;
  backgroundColor?: TColor;
  fullContentHeight?: boolean;
  alignTop?: boolean;
  drawer?: boolean;
  instant?: boolean;
  scrollableContent?: boolean;
  forbidIOSOverscroll?: boolean;
  roundedBorders?: boolean;
  openedModalIds?: string[];
  store?: Store<any, AnyAction>;
  onModalToggle?(show: boolean, id: string): void;
}

interface IState {
  opened: boolean;
  scrolledState: number;
  contentScrollPosition: number;
}

class Modal extends Component<PropsWithChildren<IModalProps>, IState> {
  instance?: HTMLDivElement | null;

  constructor(props: PropsWithChildren<IModalProps>) {
    super(props);
    this.state = {
      opened: props.show,
      scrolledState: 0,
      contentScrollPosition: 0,
    };
  }

  componentDidMount() {
    const { show, openedModalIds = [], id } = this.props;

    const firstModal = openedModalIds.indexOf(id) === 0 || openedModalIds.length === 0;

    if (show) {
      this.modalToggle(true);
    }

    if (show && firstModal) {
      if (this.instance) {
        this.instance.style.cssText = this.getPositioning();
      }
      windowScrollPositionSet(this.state.scrolledState);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: PropsWithChildren<IModalProps>) {
    const { show, openedModalIds = [], forbidIOSOverscroll } = this.props;
    const { show: showNew, openedModalIds: openedModalIdsNew = [], id } = newProps;
    const modalIndex = openedModalIdsNew.indexOf(id);
    if (
      modalIndex < 0 &&
      openedModalIds.indexOf(id) < 0 &&
      openedModalIdsNew.length < 1 &&
      !showNew
    ) {
      return;
    }
    // toggle modal to open/close
    if (show !== showNew) {
      this.modalToggle(showNew);
    }

    // if another modal is opened on the top make this one fixed in the bg
    if (
      modalIndex > -1 &&
      modalIndex + 1 < openedModalIdsNew.length &&
      typeof window !== 'undefined'
    ) {
      const scrollPosition = window.scrollY;
      this.setState({ scrolledState: scrollPosition });
      addPositionInlineStyles(scrollPosition, this.instance);
    }

    // apply styles on open
    if (
      modalIndex > -1 &&
      modalIndex + 1 === openedModalIdsNew.length &&
      openedModalIdsNew !== openedModalIds
    ) {
      if (this.instance) {
        this.instance.style.cssText = this.getPositioning();
      }
      windowScrollPositionSet(this.state.scrolledState);
      this.setState({ scrolledState: 0 });
    }

    // return to content scroll to initial position

    if (openedModalIdsNew.length === 0 && openedModalIds.length === 1) {
      scrollPositionKeep({
        show: false,
        scrollPosition: this.state.contentScrollPosition,
        forbidIOSOverscroll,
      });
      this.setState({ contentScrollPosition: 0 });
    }
  }

  componentWillUnmount() {
    const { forbidIOSOverscroll } = this.props;
    // get actual state from store as the one from props are not up to date
    const openedModalIds = this.props.store?.getState()?.bbcommon?.openedModalIds || [];
    if (this.state.opened && openedModalIds.length > 0) {
      this.modalToggle(false);
      if (openedModalIds.length === 1) {
        scrollPositionKeep({
          show: false,
          scrollPosition: this.state.contentScrollPosition,
          forbidIOSOverscroll,
        });
      }
    }
  }

  getPositioning = () => {
    const { scrollableContent } = this.props;
    return `
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 3000;
      ${scrollableContent ? 'height: 100%' : 'min-height: 100%'}`;
  };

  close = (): void => {
    const { onClose } = this.props;

    onClose?.();
  };

  modalToggle = (show = false): void => {
    if (typeof window !== 'undefined') {
      const { openedModalIds = [], id, forbidIOSOverscroll, onModalToggle } = this.props;
      const scrollPosition = window.scrollY;

      const firstModal = openedModalIds.indexOf(id) === 0 || openedModalIds.length === 0;

      if (show && firstModal) {
        scrollPositionKeep({
          show: true,
          scrollPosition,
          forbidIOSOverscroll,
        });
        this.setState({ contentScrollPosition: scrollPosition });
      }

      if (show) {
        this.setState({ opened: true });
      } else {
        this.setState({ opened: false });
      }

      onModalToggle?.(show, id);
    }
  };

  setRef: RefCallback<HTMLDivElement> = (modal) => {
    this.instance = modal;
  };

  render() {
    const {
      children,
      maxWidth,
      id,
      name,
      backgroundColor,
      fullContentHeight,
      alignTop,
      drawer,
      instant,
      alwaysMounted,
      scrollableContent,
      roundedBorders,
    } = this.props;

    const opened = typeof window !== 'undefined' && this.state.opened;
    const alwaysMountedHidden = alwaysMounted && !opened;

    const styles = componentStyles({
      maxWidth,
      backgroundColor,
      fullContentHeight,
      alignTop,
      drawer,
      instant,
      scrollableContent,
      roundedBorders,
    });

    const modalContent = (
      <div
        ref={this.setRef}
        style={{
          display: alwaysMountedHidden ? 'none' : undefined,
          position: 'fixed',
        }}
        data-name={name}>
        <Transition in timeout={0} enter exit appear>
          {(transitionState) => (
            <Box
              style={{
                ...styles.background,
                ...styles.backgroundTransition[transitionState as keyof ITransitionState],
              }}
            />
          )}
        </Transition>
        <Box {...{ id }} style={styles.wrapper}>
          <Box style={styles.clickableBg} onClick={this.close} />
          <Transition in timeout={0} enter exit appear>
            {(transitionState) => (
              <Box
                data-name="modal-body"
                style={{
                  ...styles.contentContainer,
                  ...styles.contentContainerTransition[transitionState as keyof ITransitionState],
                }}>
                {children}
              </Box>
            )}
          </Transition>
        </Box>
      </div>
    );

    const mountedContent = alwaysMounted ? modalContent : null;

    return opened ? ReactDOM.createPortal(modalContent, document.body) : mountedContent;
  }
}

export default memo(Modal);
