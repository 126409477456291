export const windowScrollPositionSet = (position: number): void => {
  // setTimeout(() => {
  window.scrollTo({
    top: position,
    // @ts-expect-error value is wrong in lib.dom.d.ts
    behavior: 'instant',
  });
  // }, 20);
};

export const addPositionInlineStyles = (position: number, component?: HTMLElement | null) => {
  component
    ? (component.style.cssText = `position: fixed; width: 100vw; height: 100vh; top: -${position}px; pointer-events: none; `)
    : undefined;
};

export const removeInlineStyles = (component: HTMLElement | null) => {
  component && component.removeAttribute('style');
};

export const scrollPositionKeep = ({
  show,
  scrollPosition,
  forbidIOSOverscroll,
}: {
  show: boolean;
  scrollPosition: number;
  forbidIOSOverscroll?: boolean;
}) => {
  const pageBody =
    process.browser && forbidIOSOverscroll ? document.querySelector('body') : undefined;
  const pageContainer = process.browser ? document.getElementById('page-container') : undefined;
  /**
    Freezes page content when modal is opened so it would now jump up and to avoid using position fixed and resets scroll position on modal close.
    Direct dom manipulation has to be done directly with .style.cssText otherwise there is a slight delay while props update that causes a jump.
   */
  if (pageContainer) {
    if (pageBody && forbidIOSOverscroll) {
      show ? (pageBody.style.overflow = 'hidden') : removeInlineStyles(pageBody);
    }

    if (show) {
      addPositionInlineStyles(window.scrollY, pageContainer);
    } else {
      removeInlineStyles(pageContainer);
      windowScrollPositionSet(scrollPosition);
    }
  }
};
